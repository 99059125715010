.card {
  margin-bottom: 1.5 * $spacer;

  @extend .shadow-sm;

  &.phonenumber, &.subscription, &.order, &.agreements {
    height: 198px;

    .status-text {
      &.pending { @extend .text-warning; }
      &.active { @extend .text-success; }
      &.dead { @extend .text-danger; }
      &.unknown { @extend .text-secondary; }
    }
    .status-bg {
      &.pending { @extend .bg-warning; }
      &.active { @extend .bg-success; }
      &.dead { @extend .bg-danger; }
      &.unknown { @extend .bg-secondary; }
    }
  }

  &.selectable {
    cursor: pointer;
  }

  &.selectable.selected {
    background: $red;
    color: #FFFFFF;
  }

  &.selectable:hover {
    background: $gray-lighter;
  }

  &.selectable.selected:hover {
    background: $red;
  }

  .card-body {
    .payment-svn-icon {
      width: 5rem;
    }
    img.profile-image {
      height: 100px;
      width: auto;
      object-fit: cover;
    }
    .profile-image-container {
      height: 100px;
    }

    .btn {
      margin: 5px;
    }

    .support-thumbnail {
      max-width: 350px;
      height: auto;
    }
  }

  &.card-nm {
    margin: 0;
  }

  .heightlighted {
  }

  .subinformations {
    color: $gray-light;
  }

  &.bg-selected {
    background-color: $gray-lighter !important;
  }

  .achievement-card-icon-complete {
    position: absolute;
    top: 0;
    right: 20px;
    color: green;
    opacity: .25;
    font-size: 5rem;
  }
  .achievement-card-icon-progress {
    position: absolute;
    top: 0;
    right: 20px;
    color: red;
    opacity: .25;
    font-size: 5rem;
  }

  .form-group-container {
    margin-bottom: 18px;

    .form-control {

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // IE specific adjustments
        height: 2rem;
      }

      display: block;
      width: 100%;
      font-size: 0.9rem;
      line-height: 2.5;
      color: #495057;
      background-clip: padding-box;
      border-radius: 0;
      background: $input-background-color;
      outline: 0;
      border: 1px solid $input-border-color;
      padding: 0 5px;
      color: rgba(34, 34, 34, 0.8);

      &.is-invalid {
        border: 1px solid red;
      }

      &:disabled, &[readonly] {
        background-color: rgba($input-background-color, .25);
        border: 1px solid rgba($input-background-color, 1);
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(58, 58, 58, 0.6);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(58, 58, 58, 0.6);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(58, 58, 58, 0.6);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(58, 58, 58, 0.6);
    }

    label {
      color: $gray;
      font-size: small;
      margin-bottom: 0;
    }

    label.ui-dropdown-label {
      display: block;
      width: 100%;
      font-size: 0.9rem;
      line-height: 2.5;
      color: #495057;
      background-clip: padding-box;
      border-radius: 0;
      background: $input-background-color;
      border: 1px solid $input-border-color;
      outline: 0;
      padding: 0 5px;
      color: rgba(34, 34, 34, 0.8);
    }

    .ui-state-default {
      border: 1px solid $input-background-color;
    }

    .ui-state-highlight {
      border-color: $red;
      background: $red;
      color: #FFFFFF;
    }

    .ui-dropdown-trigger.ui-state-default.ui-corner-right {
      background: $input-background-color;
    }

    .invalid-feedback {
      position: absolute;
      width: 90%;
      margin-top: 0;
    }

    .invalid-feedback-self {
      position: absolute;
      width: 90%;
      margin-top: 0;
    }

    .has-error {
      padding: 0;
      margin: 0;

      label {
        color: red;
      }

      li {
        list-style-type: none;
        color: red;
      }
    }
  }

  .qrcode-image {
    min-width: 250px;
    min-height: 250px;
  }

  .img-profile-form {
    max-width: 100%;
    height: auto;
    max-height: 220px;
  }

  .btn-bigger {
    border: 2px solid;
    padding: 0.325rem 0.65rem;
    font-size: 1.2rem;
    margin: 25px 0 5px 0;

    &:hover {
      border: 2px solid;
    }

    &.btn-link {
      border: 0;
      margin: 15px 0 5px 0;
    }
  }
}

.card-nb {
  border: 0;
}

.card-b {
  @include borders($card-border);
}

.card-header {

  font-weight: bold;

  &.header-red {
    background-color: $red;
    color: white;
  }

  i.icon-bg {
    display: inline-block;
    padding: $card-spacer-y $card-spacer-x !important;
    margin-top: -$card-spacer-y;
    margin-right: $card-spacer-x;
    margin-bottom: -$card-spacer-y;
    margin-left: -$card-spacer-x;
    line-height: inherit;
    color: $card-icon-color;
    vertical-align: bottom;
    background: $card-icon-bg;
    border-right: $card-border-width solid $card-border-color;
  }

  ul.nav.nav-tabs {
    margin-top: -$card-spacer-y;
    margin-bottom: -$card-spacer-y;
    border-bottom: 0;

    li.nav-item {
      border-top: 0;
      a.nav-link {
        padding: $card-spacer-y $card-spacer-x * 0.5;
        color: $text-muted;
        border-top: 0;

        &.active {
          color: $body-color;
          background: #fff;
        }
      }
    }
  }

  &.card-header-inverse {
    color: #fff;
  }

  @each $variant, $color in (primary: $primary, secondary: $gray-lighter, success: $success, info: $info, warning: $warning, danger: $danger) {
    &.card-header-#{$variant} {
      margin: -1px -1px 0;
      background: $color;
      border-bottom: 1px solid $color;
    }
  }

  .btn {
    margin-top: - $input-btn-padding-y;
  }
  .btn-sm {
    margin-top: - $input-btn-padding-y-sm;
  }
  .btn-lg {
    margin-top: - $input-btn-padding-y-lg;
  }
}
//
.card-footer {

  ul {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    table-layout: fixed;

    li {
      display: table-cell;
      padding: 0 $card-spacer-x;
      text-align: center;

      progress {
        margin-top: $card-spacer-y;
        margin-bottom: 0;
      }
    }
  }
}


//
// Background variations
//

@mixin card-variant($color) {
  background-color: $color;

  .card-header {
    border-color: darken($color,5%);
  }

  .card-header, .card-footer {
    background-color: darken($color,5%);
    //border: 0;
  }
}

@each $variant, $color in (gray: $background-color, primary: $primary, secondary: $gray-lighter, success: $success, info: $info, warning: $warning, danger: $danger) {
  .card-#{"" + $variant} {
    @include card-variant($color)
  }
}

@each $variant, $width in (100: 100px, 200: 200px, 300: 300px, 400: 400px, 500: 500px) {
  .card-mw-#{$variant} {
    min-width: $width;
  }
}

.card-inverse {
  color: #fff;

  .text-muted {
    color: rgba(255,255,255,.6) !important;
  }

}

[class*="card-outline-"] {
  .card-block {
    background: #fff !important;
  }

  &.card-outline-top {
    border-top-width: 2px;
    border-right-color: $border-color;
    border-bottom-color: $border-color;
    border-left-color: $border-color;
  }
}

// Cards with color accent
.card-accent-primary {
  @include card-accent-variant($primary);
}
.card-accent-secondary {
  @include card-accent-variant($primary);
}
.card-accent-info {
  @include card-accent-variant($info);
}
.card-accent-success {
  @include card-accent-variant($success);
}
.card-accent-warning {
  @include card-accent-variant($warning);
}
.card-accent-danger {
  @include card-accent-variant($danger);
}

// Card Actions
.card-header {
  > i {
    margin-right: $spacer * 0.5;
  }
  .card-actions {
    position: absolute;
    top: 0;
    right: 0;
    //height: inherit;

    a, button {
      display: block;
      float: left;
      width: 50px;
      padding: $card-spacer-y 0;
      margin: 0 !important;
      color: $body-color;
      text-align: center;
      background: transparent;
      border: 0;
      border-left: 1px solid $border-color;
      box-shadow: 0;

      &:hover {
        text-decoration: none;
      }

      [class^="icon-"], [class*=" icon-"] {
        display: inline-block;
        vertical-align: middle;
      }

      i {
        display: inline-block;
        transition: .4s;
      }

      i.r180 {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .input-group {
      width: 230px;
      margin: 6px;

      .input-group-addon {
        background: #fff;
      }

      input {
        border-left: 0;
      }
    }
  }
}

.card-full {
  margin-top: - $spacer;
  margin-right: - $grid-gutter-width-base * 0.5;
  margin-left: - $grid-gutter-width-base * 0.5;
  border: 0;
  border-bottom: $card-border-width solid $border-color;
}

@include media-breakpoint-up(sm) {
  .card-columns {

    &.cols-2 {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
    }
  }
}

.card {
  &.drag, .drag {
    cursor: move;
  }
}

.card-placeholder {
  background: rgba(0,0,0,.025);
  border: 1px dashed $gray-light;
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating grey and transparent backgrounds) - Example on CSUI interfaces.component.html
.rows-striped {
  /**
    For div with max md styles, like the following examples:
      Ex 1:
        Key: Value
      Ex 2:
        Key:
        Value
   */
  &.striped-md {
    @include media-breakpoint-down(sm) {
      div.row.col-sm-12 {
        @include hover {
          background-color: $table-hover-bg;
        }
      }
      div.row .col-sm-12:nth-of-type(1) {
        background-color: $table-accent-bg;
      }
    }
    @include media-breakpoint-up(md) {
      div.row {
      }
      div.row:nth-of-type(odd) {
        background-color: $table-accent-bg;
      }
    }
  }

  /**
    For div with bigger (lg) styles, like the following examples:
      Ex 1:
        Key: Value       Key: Value
      Ex 2:
        Key: Value
      Ex 3:
        Key:
        Value
   */
  &.striped-lg {
    @include media-breakpoint-only(xs) {
      div.row .col-sm-12:nth-of-type(1),
      div.row .col-sm-12:nth-of-type(3) {
        background-color: $table-accent-bg;
      }
    }
    @include media-breakpoint-only(sm) {
      div.row .col-sm-12:nth-of-type(1),
      div.row .col-sm-12:nth-of-type(3) {
        background-color: $table-accent-bg;
      }
    }
    @include media-breakpoint-only(md) {
      div.row .col-md-6:nth-of-type(1),
      div.row .col-md-6:nth-of-type(2) {
        background-color: $table-accent-bg;
      }
    }
    @include media-breakpoint-only(lg) {
      div.row .col-md-6:nth-of-type(1),
      div.row .col-md-6:nth-of-type(2) {
        background-color: $table-accent-bg;
      }
    }
    @include media-breakpoint-only(xl) {
      div.row:nth-of-type(odd) {
        background-color: $table-accent-bg;
      }
    }

  }
}



// Hover effect
//
// Placed here since it has to come after the potential zebra striping
.div-hover {
  div.row {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}

.tariff-card {
  .currently-used {
    font-weight: 400;
    width: 200px;
    cursor: default;
    text-align: center;
    vertical-align: middle;
    font-size: 0.9rem;
    padding: 0.75rem;
    background: $red;
  }
}
