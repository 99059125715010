.blur-filter {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.blur-filter-ie {
  background-color: white;
  opacity: 0.07;
  z-index: 9999;
}

.click-filter {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
}

// Fix positioning in IE
.blur-filter-ie {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
