$list-left-padding: 100px;
$hint-color: #848484;

.achievement-card {
  .achievement {
    text-align: left;
    //margin: 25px 10px;

    .achievement-item {

      align-items: center !important;

      .achievement-icon {
        min-width: 25px;
        min-height: 25px;
        font-size: 1.1rem;
        margin-right: 10px;
        line-height: 1.4;
      }
    }

    //&:hover {
    //  .progress {
    //    height: 10px;
    //  }
    //}

  }

  .progress-text {
    margin-right: 35px;
  }

  .progress {
    border-radius: 0;
    margin-right: 35px;
    height: 3px;
  }

  .pie-progress-size {
    font-size: 1.2em;
    width: 25px;
    height: 25px;
    margin-right: 13px;
    margin-top: 4px;
  }

}

.achievement-tooltip {
  line-height: 1.5 !important;
}

.achievement-container {
  color: white;

  &.controlled-box {
    @include media-breakpoint-up(lg) {
      height: 100%;
    }
  }

  &.normal {
    background: white;
    color: #858994;
  }
  &.highlight {
    background: #858994;
    color: white;
  }

  .form-group-container {
    width: 80%;
  }

  .form-control {
    font-weight: bold;
    text-align: center;
  }

  .achievement-container-body {
    .benefits {
      text-align: left;
      //margin: 25px 10px;

      .benefits-item {

        align-items: center !important;

        .benefits-icon {
          line-height: 1.4;
          font-weight: 900;
          width: 25px;
          height: 25px;
          font-size: 1.1rem;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }

  .achievement-container-header {
    //padding: 20px;

    .level-type {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-weight: 700;
      line-height: 1.2;
      font-size: 1.575rem;

    }
    .level-description {
      height: 40px;
      font-size: 0.8rem;
    }

  }

}
