@use "sass:math";

$pie_width: 400px;
$pie_height: $pie_width * 0.5;
$wrapper-bg:  #E8E8E8;
$green: #47CF73;
$orange: #FCD000;
$text-color: #394955;
$body-bg: #fff;
$legend-bg: #f0f0f0;
$pie-bars: 2;
$pie-border: 40;

$bar-color-list: $red, $orange;

.pie-wrapper{
  position: relative;
  width:$pie_width;
  height:$pie_height;
  overflow:hidden;

  @for $i from 1 through $pie-bars {
    .arc-#{$i}, &:before {
      content: '';
      width: $pie_width;
      height: $pie_height;
      position: absolute;
      -ms-transform-origin: 50% 0%;
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      left: 0;
      box-sizing: border-box;
    }

    .arc-#{$i} {
      border: #{math.div($pie-border, $i)}px solid $green;
      border-top: none;
      border-radius: 0 0 300px 300px;
      top: 100%;
      z-index: (1 + $i);

      //&::before{
      //  content: "";
      //  height:1px;
      //  width:5px;
      //  position: absolute;
      //  top: 0;
      //}
    }
  }

  &:before{
    border: #{$pie-border}px solid $wrapper-bg;
    border-bottom: none;
    top: 0;
    z-index: 1;
    border-radius: 300px 300px 0 0;
  }

  .score{
    color: $text-color;
    font-size: 28px;
    display: block;
    width: $pie-width;
    text-align: center;
    margin-top: ($pie-height * 0.5) + 20;
  }
}

@for $i from 1 through 100 {

  @for $b from 1 through $pie-bars {
    .arc-#{$b}[data-value="#{$i}"]{
      //@mixin animation(fill 2s);
      //rotate(#{$i * 1.8}deg);
      transform: rotate((#{$i * 1.8}deg));
      transition:All 5s ease;
      border-color: nth($bar-color-list, $b);

      &:after{
        content: '';
        position: absolute;
        left:  -40px;
        top: 5px;
      }

      &::before{
        background-color: nth($bar-color-list, $b);
      }
    }
  }
}

.legend{
  display: inline-block;
  //width:150px;
  vertical-align: text-bottom;
  //margin-left:100px;
  background-color: $legend-bg;
  padding: 5px;
  font-size: 1em;

  span{
    width:20px;
    height: 20px;
    display: inline-block;
    //margin: 0 10px 0 20px;

    @for $b from 1 through $pie-bars {
      &.arc-legend-#{$b} {
        background-color: nth($bar-color-list, $b);
      }
    }
  }
}

//@include keyframes(fill){
//  0%{rotate(0deg));
//    border-color: $red;
//  }
//  50%{@include rotate(180deg);
//    border-color: $green;
//  }
//}
