.content-card-container-bg {
  align-items: center;
  display: flex;
  justify-content: center;

  .content-card-container {
    position: relative;
    overflow: hidden;
  }

  .content-card {
    h1 {
      margin-bottom: 25px;
    }

    .btn {
      border: 2px solid;
      padding: 0.325rem 0.65rem;
      font-size: 1.2rem;
      margin: 25px 0 5px 0;
      &.btn-link {
        border: 0;
        margin: 15px 0 5px 0;
      }
    }

    img {
      max-width: 45vh;
      height: auto;
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(58, 58, 58, 0.6);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(58, 58, 58, 0.6);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(58, 58, 58, 0.6);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(58, 58, 58, 0.6);
    }
  }
}
