/**
 * Styling for PrimeNG component toast
 */

body .ui-toast {
  z-index: $zindex-popover !important;
}

body .ui-toast .ui-toast-message.ui-toast-message-error {
    background-color: $red;
    border: 0 none;
    color: white;
}

body .ui-toast .ui-toast-message.ui-toast-message-error .ui-toast-close-icon {
  color: white;
}


// Close icon of a message.
.ui-toast-close-icon {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;

  &:before {
    content: "\f00d";  /* this is your text. You can also use UTF-8 character codes as I do here */
  }

}
