$list-left-padding: 100px;
$hint-color: #848484;

.recommended-product {
  position: absolute;
  right: -6px;
  top: -24px;
  z-index: 10;
  padding: 15px;
  color: white;
}

.price-card {
  margin-bottom: 20px;
  //background: #fff;
  text-align: center;
  overflow: hidden;
  position: relative;
  color: white;

  &.one-upgrade {
    width: 450px;
  }

  //min-height: 500px;

  &.normal:before {
    content: "";
    width: 7%;
    height: 40px;
    position: absolute;
    top: 170px;
    left: 0;
    -webkit-transform: skewY(-40deg);
    transform: skewY(-40deg);
    z-index: 0;
    background: #3b3b3b;
    border-radius: 8px;
  }
  &.normal.upgrade:before {
    top: 190px;
  }
  &.normal.one-upgrade:before {
    top: 125px;
  }
  &.normal {
    .price-card-header {
      background: #3b3b3b;
    }
  }
  &.standard {
    .price-card-header {
      background: #3b3b3b;
    }
  }
  &.highlight:before {
    content: "";
    width: 7%;
    height: 40px;
    position: absolute;
    top: 170px;
    left: 0;
    -webkit-transform: skewY(-40deg);
    transform: skewY(-40deg);
    z-index: 0;
    background: $red;
    border-radius: 8px;
  }
  &.highlight.upgrade:before {
    top: 190px;
  }
  &.highlight.one-upgrade:before {
    top: 120px;
  }
  &.product:before {
    top: 190px;
  }
  &.highlight {
    .price-card-header {
      background: $red;
    }
  }

  .form-group-container {
    width: 80%;
  }

  .form-control {
    font-weight: bold;
    text-align: center;
  }

  select {
    text-align: center;
    text-align-last: center;
    text-transform: uppercase;
    font-weight: bold;

  }

  .price-card-footer {
    margin-bottom: 20px;
    align-content: end;
    .btn {
      width: 200px;
    }
  }

  .price-card-body {
    padding: 5px 20px;
    color: $font-color;
    min-height: 280px;

    &.product {
      height: 250px;
      &.one {
        height: 200px;
      }
      &.full-with-min-h {
        height: auto;
        min-height: 250px;
      }
    }
    &.upgrade {
      height: 290px;
    }
    &.sla-product {
      height: 180px;
    }

    .concurrent-user {
      text-transform: uppercase;
      font-weight: bold;
    }

    .concurrent-user-hint {
      margin-bottom: 10px;
      font-weight: 700;

      .concurrent-user-icon {
        margin-left: 5px;
        color: $hint-color;
      }
    }

    .subscription-content {
      padding: 0 0 0 $list-left-padding;
      margin: 20px 0;
      list-style: none;
      text-align: left;
    }

    .features {


      text-align: left;
      //margin: 25px 10px;

      .features-item {

        align-items: center !important;

        .features-icon {
          font-weight: 900;
          width: 25px;
          height: 25px;
          font-size: 1.5rem;
          border-radius: 50%;
          color: $hint-color;
          margin-right: 10px;
        }
      }
    }
  }

  .price-card-header {
    padding: 20px 20px 60px;
    text-align: center;
    position: relative;
    height: 200px;
    min-height: 200px;
    &.upgrade {
      height: 220px;
    }
    &.product {
      height: 220px;
    }
    &.one-upgrade {
      min-height: 160px;
      height: 160px;
    }
    &.phonenumber {
      padding: 0;
      margin: 0;

      .phonenumber-type-container {
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    .subscription-type {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-weight: 700;
      line-height: 1.2;
      font-size: 1.575rem;

      &.product {
        text-transform: none;
      }
    }



    .subscription-description {
      height: 40px;
      font-size: 0.8rem;
    }

    .subscription-price {

      .price {
        font-size: 2em;
        font-weight: 700;
      }

      @include media-breakpoint-up(sm) {
        .price {
          font-size: 3em;
        }
      }
      @include media-breakpoint-up(md) {
        .price {
          font-size: 3em;
        }
      }
      @include media-breakpoint-up(lg) {
        .price {
          font-size: 2em;
        }
      }
      @include media-breakpoint-up(xl) {
        .price {
          font-size: 3em;
        }
      }

      .currency {
        font-size: 3rem;
        font-weight: 700;
        margin-left: 5px;
      }
      .time-billing {
        font-size: 0.8rem;
        margin-left: 10px;

        &.product {
          margin-left: 0;
        }
      }
    }
  }

  .product {
    .product-features-icon {
      font-weight: 700;
      color: $hint-color;
      margin-right: 10px;
      margin-top: 3px;
    }
    .cu-info {
      height: 10px;
    }
  }

}

body {
  .price-card {
    .product {
      .ui-inputnumber-input {
        font-size: 1.2rem;
        text-align: center;
        font-weight: 700;
      }

      .ui-inputnumber-button {
        font-size: 1.5rem;
      }
    }
  }
}
