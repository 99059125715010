/**
 * Webfonts font awesome
 */
$fa-font-path: '/assets/webfonts';

/**
 * Fonts
 */
$font-size-base:              0.9rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 0.125);
$font-size-sm:                ($font-size-base * .875);

/**
 * Icon size for text styled icon like fontawsome
 */
$icon-size-base:              $font-size-base;
$icon-size-big:               ($icon-size-base + .25);
$icon-size-bigger:            ($icon-size-base + .5);
$icon-size-biggest:            ($icon-size-base + 2);
$icon-size-small:             ($icon-size-base - .25);
$icon-size-smaller:           ($icon-size-base - .25);


/**
 * Colors
 */
// Basic colors
//This defines our basic hue and saturation, all other colors are calculated from this one
$__base_color:              #d20134;

//To get the other colors, we just rotate the hue value of the HSL Color Space
$red:                       $__base_color;
$pascom_color:              $__base_color;

// Bootstrap colors
$primary:                   $red;

$primary-hover:         darken($red, 15%);

// Standard gray tones
$gray-base:                 #525252;
$gray-verydark:             #171717;
$gray-darker:               #262626;
$gray-dark:                 #404040;
$gray:                      $gray-base;
$gray-light:                #737373;
$gray-lighter:              #a3a3a3;
$gray-verylight:            #d4d4d4;
$black:                     #0a0a0a;

// Body
$body-color:                $gray-dark;
$font-color:                $gray-dark;
$background-color:          #f5f5f5;
$app-background-color:      $background-color;

$font-color:                #333;

// Border colors
$border-color-default:      #bababa;
$border-color:              $gray-lighter;

// Navbar colors
$navbar-brand-bg:                     #fff;
$navbar-color:                        $gray-light;
$navbar-hover-color:                  $gray-dark;
$navbar-active-color:                 $gray-dark;
$navbar-disabled-color:               $gray-lighter;
$navbar-bg:                           #fff;
$navbar-color:                        $gray-light;
$navbar-hover-color:                  $gray-dark;
$navbar-active-color:                 $gray-dark;
$navbar-disabled-color:               $gray-lighter;

// Navbar border
$navbar-brand-border: (
  bottom: (
    size:    1px,
    style:   solid,
    color:   $border-color
  )
);

/**
 * Forms
 */
$input-background-color:      #f5f5f5;
$input-border-color:          #e9e6e6;

/**
 * Searchbar
 */
$searchbar-border: (
  all: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
);
$card-border: (
  all: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
);

/**
 * Side Navigation
 */

$enable-sidebar-nav-rounded:          false;
$navbar-brand-minimized-width:        50px;
$navbar-brand-minimized-bg:           $navbar-brand-bg;
$navbar-brand-minimized-logo:         url('/assets/images/pascom_logo.png');
$navbar-brand-minimized-logo-size:    24px;
$navbar-brand-minimized-border:       $navbar-brand-border;

$navbar-toggler-icon:                 str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-toggler-icon-hover:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");

$nav-link-text-margin-left:           15px;

// Navbar

$navbar-height:                       55px;
$sticky-message-bar:                 40px;
$navbar-border: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) ;
$navbar-brand-width:                  155px;
$navbar-brand-logo-height:            45px;
$navbar-brand-logo:                   url('/assets/images/pascom_logo.png');
$navbar-brand-logo-size:              140px auto;
$navbar-brand-border: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
);

$navbar-brand-minimized-width:        50px;
$navbar-brand-minimized-bg:           $navbar-brand-bg;
$navbar-brand-minimized-logo:         url('/assets/images/pascom_logo.png');
$navbar-brand-minimized-logo-size:    24px;
$navbar-brand-minimized-border:       $navbar-brand-border;

$navbar-toggler-icon:                 str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-toggler-icon-hover:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");

// Sidebar

$sidebar-width:                     200px;
$sidebar-padding:                   0;
$sidebar-compact-width:             50px;
$sidebar-compact-height:            $sidebar-compact-width;
$sidebar-color:                     #fff;
$sidebar-bg:                        $red;
$sidebar-header-bg:                 rgba(0,0,0,.2);
$sidebar-footer-bg:                 rgba(0,0,0,.2);
$sidebar-borders:                   none;
$mobile-sidebar-width:              60px;

// Sidebar Navigation

$sidebar-nav-color:                 #fff;
$sidebar-nav-title-padding-y:       .75rem;
$sidebar-nav-title-padding-x:       1rem;
$sidebar-nav-title-color:           $gray-lighter;
$sidebar-nav-link-padding-y:        .75rem;
$sidebar-nav-link-padding-x:        1rem;
$sidebar-nav-link-color:            #fff;
$sidebar-nav-link-bg:               transparent;
$sidebar-nav-link-icon-color:       $red;

$sidebar-nav-link-borders:          0;
$sidebar-nav-link-hover-color:      #fff;
$sidebar-nav-link-hover-bg:         darken($red, 15%);
$sidebar-nav-link-hover-icon-color: #fff !important;

$sidebar-nav-link-hover-borders:    0;
$sidebar-nav-link-active-color:     #fff;
$sidebar-nav-link-active-bg:        darken($red, 15%);
$sidebar-nav-link-active-icon-color: $red;

$sidebar-nav-link-active-borders:   0;

$sidebar-nav-dropdown-color:        #fff;
$sidebar-nav-dropdown-bg:           rgba(0,0,0,.2);
$sidebar-nav-dropdown-borders:      0;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop:  990;
$zindex-navbar:             1000;
$zindex-dropdown:           1000;
$zindex-fixed:              1030;
$zindex-sticky:             1030;
$zindex-modal-backdrop:     1040;
$zindex-modal:              1050;
$zindex-popover:            1060;
$zindex-tooltip:            1070;

// Aside

$aside-menu-width:                  250px;
$aside-menu-color:                  $gray-dark;
$aside-menu-bg:                     #fff;
$aside-menu-borders: (
  left: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);

$aside-menu-nav-padding-y:          .75rem;
$aside-menu-nav-padding-x:          1rem;

// Cards

$card-icon-bg:                      transparent;
$card-icon-color:                   $body-color;

/**
 * Grid columns
 */
$grid-columns:               12;
$grid-gutter-width-base:     30px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);

/**
 * Cards
 */

// Glass card
$glass-card-bg: "";

/**
 * Footer
 */

// color
$footer-bg-color: #fff;

/**
 * Table
 */

// odd color for zebra table
$table-accent-bg: rgba(0, 0, 0, 0.03);

// hover background for table
$table-hover-bg: rgba(65, 0, 0, 0.08);

/**
 * Cards
 */

// Glass card
$glass-card-bg: "/assets/images/mypascom-login.jpg";

$article-card-bg: #e5f0f5;
$article-card-border: #e3edf2;

$ticket-status-new: #faab00;
$ticket-status-open: #faab00;
$ticket-status-closed: #38ad69;
$ticket-status-pending-reminder: #42484c;
$ticket-status-pending-close: #42484c;

$ticket-attachment-files-icon: $gray-light;

$phonenumbers-status-pending: #faab00;
$phonenumbers-status-active: #38ad69;

