app-searchbox {
  width:100%;
}

.search-container {
  vertical-align: middle;
  white-space: nowrap;
  position: relative;

  &.border {
    @include borders($searchbar-border);
  }

  input.search-input {
    height: 30px;
    width: 100%;
    background: #FFFFFF;
    border: none;
    font-size: 0.9rem;
    float: left;
    //color: #000;
    padding-left: 30px;
  }

  input.search-input::-webkit-search-cancel-button {
    -webkit-appearance: none; // overwrite webkit standard with our css statements
    display: hidden;
    z-index: -30000;
  }

  input.search-input::-webkit-input-placeholder {
    color: #65737e;
  }

  input.search-input:-moz-placeholder { /* Firefox 18- */
    color: #65737e;
  }

  input.search-input::-moz-placeholder {  /* Firefox 19+ */
    color: #65737e;
  }

  input.search-input:-ms-input-placeholder {
    color: #65737e;
  }

  .search-loading {
    position: absolute;
    top: 50%;
    margin-left: 5px;
    z-index: 1;
    color: $red;
    margin-top: 3px;

    img {
      height: 16px;
      width: 16px;
    }
  }

  .search-icon {
    position: absolute;
    left: 0;
    top: 50%;
    margin-left: 5px;
    z-index: 1;
    color: $red;
    font-size: 1.1rem;
    margin-top: 3px;
  }
}
