.ui-confirmdialog {
  i {
    color: $red;
  }
}

body .ui-dialog {
  .ui-dialog-titlebar {
    background-color: white;
    border: 0;
  }

  .ui-dialog-footer {
    background-color: white;
    border: 0;
  }

  .ui-dialog-content {
    background-color: white;
    border: 0;
  }
}
