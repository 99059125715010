// FIXME: Breaks dynamic forms (need to get the layouting right, probably we need a custom control here)
///* Customize the label (the container) */
.custom-cb {
  label.checkbox-container {
    display: block;
    position: relative;
    padding-left: 25px;
    //margin-bottom: 12px;
    cursor: pointer;
    //font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Create a custom checkbox */
    .checkbox {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 2px $red solid;
      //background-color: $red;
    }

    /* Create the checkbox/indicator (hidden when not checked) */
    .checkbox:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  /* Hide the browser's default checkbox */
  label.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* On mouse-over, add a grey background color */
  label.checkbox-container:hover input ~ .checkbox {
    background-color: $red;
  }

  /* When the checkbox is checked, add a pascom background */
  label.checkbox-container input:checked ~ .checkbox {
    background-color: $red;
  }


  /* Show the checkbox when checked */
  label.checkbox-container input:checked ~ .checkbox:after {
    display: block;
  }

  /* Style the checkbox/indicator */
  label.checkbox-container .checkbox:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}