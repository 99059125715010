.p-inputtext, .p-dropdown {
  width: 100%;
}

li.ui-message.ui-messages-error {
  position: absolute;
  width: 80%;

  padding: 0;
  margin: 0;
  list-style-type: none;
  color: red;
  font-size: 0.75rem;
}

.ng-dynamic-forms-input ul {
  margin: 0;
  padding: 0;
}

.p-multiselect {
  width: 100%;
}

@include media-breakpoint-up(md) {
  .p-multiselect {
    width: 40vw;
  }
}
