.sidebar {
  padding: $sidebar-padding;
  color: $sidebar-color;
  background: $sidebar-bg;
  @include borders($sidebar-borders);
  z-index: 2;

  .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 $spacer;
    font-size: 24px;
    font-weight: 800;
    line-height: $navbar-height;
    color: $sidebar-color;
    background: 0;
    border: 0;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }

  .sidebar-nav {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include sidebar-width($sidebar-borders, $sidebar-width);

    .nav-logo-sm {
      display: none;
    }

    &::-webkit-scrollbar {

      position: absolute;
      width: 10px;
      margin-left: -10px;
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar-track {
      background-color: lighten($sidebar-bg, 5%);
      border-right: 1px solid darken($sidebar-bg, 5%);
      border-left: 1px solid darken($sidebar-bg, 5%);
    }

    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: darken($sidebar-bg, 10%);
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px;
    }
  }

  .nav {
    @include sidebar-width($sidebar-borders, $sidebar-width);
    flex-direction: column !important;

    .nav-title {
      padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
      font-size: 11px;
      font-weight: 600;
      color: $sidebar-nav-title-color;
      text-transform: uppercase;
    }

    img.nav-icon {
      height: 24px;
      width: 24px;
      margin-right: 10px;
    }

    .divider {
      height: 10px;
    }

    .nav-item {
      position: relative;
      margin: 0;
      transition: background .3s ease-in-out;

      &.active {
        color: $sidebar-nav-link-active-color;
        background: $sidebar-nav-link-active-bg;
        @include borders($sidebar-nav-link-active-borders);
      }

      ul {
        max-height: 0;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
        transition: max-height .3s ease-in-out;

        li {
          background: yellow;
          padding: 0;
          list-style: none;

        }
      }

      .nav-link {
        display: block;
        padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
        color: $sidebar-nav-link-color;
        text-decoration: none;
        background: $sidebar-nav-link-bg;
        @include borders($sidebar-nav-link-borders);
        @if $enable-sidebar-nav-rounded {
          border-radius: $border-radius;
        }

        .nav-link-icon {
          padding-left: 3px !important;
        }

        .nav-link-text {
          margin-left: $nav-link-text-margin-left;
        }

        &:hover {
          color: $sidebar-nav-link-hover-color !important;
          background: $sidebar-nav-link-hover-bg !important;
          @include borders($sidebar-nav-link-hover-borders);

        }

        &.active {
          color: $sidebar-nav-link-active-color;
          background: $sidebar-nav-link-active-bg;
          @include borders($sidebar-nav-link-active-borders);

        }

        [class^="icon-"], [class*=" icon-"] {
          display: inline-block;
          margin-top: -4px;
          vertical-align: middle;
        }

        .badge {
          float: right;
          margin-top: 2px;
          // margin-left: 10px;
        }

        &.nav-dropdown-toggle {

          &::before {
            position: absolute;
            top: ($sidebar-nav-link-padding-y + ($font-size-base * 0.25));
            right: $sidebar-nav-link-padding-x;
            display: block;
            width: ($font-size-base);
            height: ($font-size-base);
            padding: 0;
            font-size: $font-size-base;
            line-height: ($font-size-base * 3 * 0.25);
            text-align: center;
            content: "\2039";
            transition: .3s;
          }
        }
      }

      &.nav-dropdown {
        &.open {
          background: $sidebar-nav-dropdown-bg;
          @include borders($sidebar-nav-dropdown-borders);
          @if $enable-sidebar-nav-rounded {
            border-radius: $border-radius;
          }
          > ul, > ol {
            // display: inline;
            max-height: 1000px;
          }

          a.nav-link {
            color: $sidebar-nav-dropdown-color;
            border-left: 0 !important;
          }

          > .nav-link.nav-dropdown-toggle::before {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
          }

          .nav-dropdown.open {
            border-left: 0;
          }
        }
        &.nt {
          transition: 0s !important;
          > ul, > ol {
            transition: 0s !important;
          }
          a.nav-link {
            &.nav-dropdown-toggle {

              &::before {
                transition: 0s !important;
              }
            }
          }
        }
      }

      a.nav-label {
        display: block;
        padding: ($sidebar-nav-link-padding-y * 0.125) $sidebar-nav-link-padding-x;
        color: $sidebar-nav-title-color;

        &:hover {
          color: $sidebar-color;
          text-decoration: none;
        }
      }

      @if (lightness( $sidebar-bg ) < 40) {
        .progress {
          background-color: lighten($sidebar-bg, 15%) !important;
        }
      }
    }
  }
}

//
// Mobile layout
//

@include media-breakpoint-down(md) {
  .sidebar {
    .sidebar-nav {
      .nav-logo-md {
        display: none;
      }
      .nav-logo-sm {
        display: block;
      }
    }
    .nav {
      .nav-text-md {
        display: none;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .sidebar {
    display: none;
  }
}
