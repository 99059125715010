/**
 * Step 1 - Variables
 */
@import "./styles/pascom18/variables";

/**
 * Step 2 - Bootstrap
 */
@import "../node_modules/bootstrap/scss/bootstrap";

/**
 * Step 3 - primeng theme
 */
@import "./styles/pascom18/primeng_lara_light";

/**
 * Step 4 - Components
 */
@import "./styles/pascom18/filters";
@import "./styles/pascom18/components";
