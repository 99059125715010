.nav.link-navigation {

  margin-bottom: 10px;

  .nav-item {
    border-bottom: 2px solid rgba(0,0,0,0);
  }

  .nav-item:hover {
    border-bottom: 2px solid $red;
  }
  .nav-item.active {
    border-bottom: 2px solid $red;
  }

}
