.glass-card-container-bg {
  //padding: 50px;
  height: 90vh;
  @include glass-card-bg($glass-card-bg);
  background-size: cover;

  align-items: center;
  display: flex;
  justify-content: center;

   .glass-card-container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
  }
  .glass-card-bg:before {
    content: "";
    @include glass-card-bg-before($glass-card-bg);

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS Fallback because it doesn't support blur outside of svg */
      background: none;
      background-color: #ededed;
      opacity: 0.9;
    }

    -webkit-filter: blur(20px);
    filter: blur(20px);
    position: absolute;
    top: -50px;
    right: -50px;
    bottom: -50px;
    left: -50px;
    z-index: 99;
    background-size: cover;
  }

  .glass-card {
    position: relative;
    z-index: 100;
    //height: 100vh;
    min-width: 350px;
    padding: 35px;
    background: rgba(255, 255, 255, 0.3);
    font-size: 0.8rem;

    h3 {
      margin-bottom: 25px;
    }

    h3.sign-headline {
      max-width: 250px;
    }

    .form-group-container {
      margin-bottom: 40px;
      .invalid-feedback {
        position: absolute;
        width: 80%;
      }
    }

    .form-control {
      display: block;
      width: 100%;
      font-size: 0.9rem;
      line-height: 1.5;
      color: #495057;
      background-clip: padding-box;
      border-radius: 0;
      background: rgba(255, 255, 255, 0) 0 0;
      outline: 0;
      border: 0;
      border-bottom: 1px solid rgba(34, 34, 34, 0.8);
      padding: 0;
      color: rgba(34, 34, 34, 0.8);

      &.is-invalid {
        border-bottom: 1px solid red;
      }
    }

    .has-error {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        color: red;
        max-width: 230px;
      }
    }

    .dynamic-form {
      width: 100%;
    }

    .disabled {
      background-color: rgba(34, 34, 34, 0);
      border-bottom: 0;
    }

    .btn.btn-outline-primary {
      border: 1px solid;
      padding: 0.325rem 0.65rem;
      font-size: 1.2rem;
      margin-top: 25px;
    }

    .btn.btn-link {
      margin-top: 25px;
      color: $red;

      &:hover {
        text-decoration: underline;
      }
    }


    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(58, 58, 58, 0.6);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(58, 58, 58, 0.6);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(58, 58, 58, 0.6);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(58, 58, 58, 0.6);
    }

    dynamic-ng-bootstrap-form-group {
      width: 100%;
    }

    //div {
    //  text-align: center;
    //  color: rgba(0,0,0,0.45);
    //  font-size: 3.8rem;
    //  line-height: 0vh;
    //}
  }
}
