// IE10&11 Flexbox fix
@media all and (-ms-high-contrast:none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

// app-dashboard and app-root are Angular2 selectors. You can add here your own selectors if you need.
.app,
app-dashboard,
app-root {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &.app-viewport {
    height: 96vh;
  }
}

.app-header {
  flex: 0 0 $navbar-height;
}

.app-body {
  // FIXME: Test without flex because we get some side effects with other components
  //display: flex;
  //flex-grow:1;
  //flex-direction: row;
  overflow-x: hidden;

  background-color: $app-background-color;

  .main {
    //flex: 1;
  }

  .sidebar {
    // $sidebar-width is the width of the columns
    flex: 0 0 $sidebar-width;
    // put the nav on the left
    order: -1;
  }

  .aside-menu {
    // $aside-menu-width is the width of the columns
    flex: 0 0 $aside-menu-width;
  }
}

//
// header
//
.header-fixed {
  .app-header {
    position: fixed;
    z-index: $zindex-navbar;
    width: 100%;
  }
  .app-body {
    margin-top: $navbar-height;

    &.sticky-message-bar {
      margin-top: $navbar-height + $sticky-message-bar;
    }
  }
}

//
// Sidebar
//
.sidebar-hidden {

  .sidebar {
    margin-left: - $sidebar-width;
  }
}

.sidebar-fixed {

  .sidebar {
    position: fixed;
    height: 100%;

    .sidebar-nav {
      height: calc(100vh - #{$navbar-height});
    }
  }

  .main, .app-footer {
    margin-left: $sidebar-width;
  }

  &.sidebar-hidden {

    .main, .app-footer {
      margin-left: 0 !important;
    }
  }
}

.sidebar-off-canvas {
  .sidebar {
    position: fixed;
    z-index: $zindex-navbar - 1;
    height: 100%;


    .sidebar-nav {
      height: calc(100vh - #{$navbar-height});
    }
  }
}

.sidebar-compact {
  .sidebar {
    flex: 0 0 $sidebar-compact-width;
  }

  &.sidebar-hidden {

    .sidebar {
      margin-left: - $sidebar-compact-width;
    }
  }

  &.sidebar-fixed {

    .main, .app-footer {
      margin-left: $sidebar-compact-width;
    }

    &.sidebar-hidden {

      .main, .app-footer {
        margin-left: 0;
      }
    }
  }
}

//
// Aside Menu
//
.aside-menu-hidden {

  .aside-menu {
    margin-right: - $aside-menu-width;
  }
}

.aside-menu-fixed {

  .aside-menu {
    position: fixed;
    right: 0;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }

  .main, .app-footer {
    margin-right: $aside-menu-width;
  }

  &.aside-menu-hidden {

    .main, .app-footer {
      margin-right: 0;
    }
  }
}

.aside-menu-off-canvas {
  .aside-menu {
    position: fixed;
    right: 0;
    z-index: $zindex-navbar - 1;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }
}

//
// Footer
//
.footer-fixed {

  //.app-footer {
  //  position: fixed;
  //  bottom: 0;
  //  z-index: $zindex-navbar;
  //  width: 100%;
  //}

  .app-body {
    //margin-bottom: $footer-height;
  }
}

//
// Animations
//
//.app-header,
//.app-footer,
//.sidebar,
//.main,
//.aside-menu {
//  transition-duration: $layout-transition-speed, $layout-transition-speed;
//  transition-property: margin-left, margin-right;
//}

//
// Mobile layout
//

@include media-breakpoint-down(md) {

  .app-body {
    margin-top: $navbar-height;
  }

  .sidebar {
    position: fixed;
    width: $mobile-sidebar-width;
    height: 100%;
    //margin-left: - $mobile-sidebar-width;

    .sidebar-nav,
    .nav {
      width: $mobile-sidebar-width !important;
    }
  }

  .aside-menu {
    margin-right: - $aside-menu-width;
  }

  .sidebar-mobile-show {

    .sidebar {
      width: $mobile-sidebar-width;
      margin-left: 0;

      .sidebar-nav {
        height: calc(100vh - #{$navbar-height});
      }
    }

  }
    .main {
      margin-left: $mobile-sidebar-width !important;
    }
}

@include media-breakpoint-down(xs) {
  .sidebar .sidebar-nav, .sidebar .nav {
    width: 0 !important;
  }

  .main {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.main .container-fluid {
  padding: 25px 10px;
}

.headline-buttonbar {
  margin-bottom: 25px;
}

.cardheader-buttonbar {
  margin: 0;
}

.underlined {
  border-bottom: 3px solid $gray-lighter;
}

// Loading

.content-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 1;

  .spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.footer-wrapper {
  text-align: end;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: $background-color;

  .footer-content {
    padding: 2px 10px;
    border-radius: 10px 0 0 0;
    background: white;
  }
}

pdf-viewer {
  height: 100vh;
}
