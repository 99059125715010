body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  background-color: $white !important;
  color: $font-color;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: $red;
  background-color: $red;
  color: #ffffff;

  :hover {
    border-color: $red;
    background-color: $red;
    color: #ffffff;
  }
}

body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
  color: $red;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: $red;
  background-color: $red;
  color: #ffffff;
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
  background-color: $red;
}

body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
  color: #ffffff;
  background-color: $red;
}

.dropdown-menu-headline {
  font-weight: 500;
  color: gray;
  font-size: .75rem;
}

.profile-menu.divider {
  margin: 8px 0;
  padding: .5px 0 0 0;
  background-color: #bfbfbf;
}