.app-header .navbar {
  position: relative;
  flex-direction: row;
  justify-content: start;
  height: $navbar-height;
  padding: 0;
  margin: 0;
  background-color: $navbar-bg;

  .navbar-profile {
    margin-right: 10px;

    .navbar-profile-icon {
      margin-right: 5px;
    }

    .navbar-profile-text {

    }

  }

  .navbar-brand {
    display: inline-block;
    width: $navbar-brand-width;
    height: $navbar-brand-logo-height;
    padding: $navbar-padding-y $navbar-padding-x;
    margin-right: 0;
    background-color: $navbar-brand-bg;
    background-image: $navbar-brand-logo;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $navbar-brand-logo-size;
  }

  .navbar-toggler {
    min-width: 50px;
    padding: $navbar-toggler-padding-y 0;

    &:hover .navbar-toggler-icon {
      background-image: $navbar-toggler-icon-hover;
    }
  }

  .navbar-toggler-icon {
    height: 23px;
    background-image: $navbar-toggler-icon;
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0 !important;
    text-align: center;

    button {
      margin: 0 auto;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      background: 0;
      border: 0;

      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0;
      }

      > .img-avatar {
        height: $navbar-height - 20px;
        margin: 0 10px;
      }
    }
  }

  .dropdown-menu {
    padding: 0.25rem 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;
    padding: 10px;

    &:hover {
      color: $sidebar-color;
      text-decoration: none;
      background: $red !important;
    }
  }
}

.navbar-brand {
  color: $navbar-active-color;

  @include hover-focus {
    color: $navbar-active-color;
  }
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0,0,0,.075);
}

.custom-dropdown-menu {
  .dropdown-menu {
    padding: 0.25rem 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;
    padding: 10px;

    &:hover {
      color: $sidebar-color;
      text-decoration: none;
      background: $red;
      i {
        color: $sidebar-color !important;
      }
    }
  }

  .hide-dropdown-icon.dropdown-toggle::after {
    display: none;
  }
}

.navbar-toggle {
  display: flex;
  justify-content: center;

  button,
  input[type="button"] {
    border: none;
    border-radius: 0;
    color: #737373;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    //padding: 1em 1.5em;
    text-transform: uppercase;
    transition: all 0.25s ease-in-out;
  }

  .menu-toggle:focus {
    outline: thin dotted;
    outline-offset: -2px;
  }

  .icon {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    position: relative; /* Align more nicely with capital letters */
    top: -0.0625rem;
  }

  /* Menu toggle styles. */

  .icon-menu-toggle {
    width: 1.25rem;
    height: 1.25rem;
    top: 0;
  }

  /* Animate menu icon (lines). */
  .svg-menu-toggle .line {
    opacity: 1;
    transform: rotate(0) translateY(0) translateX(0);
    transform-origin: 1rem 1rem;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
  }
  .svg-menu-toggle .line-1 {
    transform-origin: 1rem 2.5rem;
  }
  .svg-menu-toggle .line-3 {
    transform-origin: 1rem 3.5rem;
  }

  .menu-toggle.opened .svg-menu-toggle .line-1 {
    transform: rotate(45deg) translateY(0) translateX(0);
  }
  .menu-toggle.opened .svg-menu-toggle .line-2 {
    opacity: 0;
  }
  .menu-toggle.opened .svg-menu-toggle .line-3 {
    transform: rotate(-45deg) translateY(0em) translateX(0em);
  }

  /* Text meant only for screen readers. */
  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
}

.mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  .mobile-menu-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 55px;
    width: 100vw;
    height: 100vh;
  }
  .mobile-menu-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    background-color: white;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .nav-link {
      font-size: 1.25rem;
      &:hover {
        color: $sidebar-nav-link-active-bg !important;
      }
      &.active {
        color: $sidebar-nav-link-active-bg !important;
      }
    }
  }
}
