.ticket {
    .sub {
        color: $gray-light;
        font-size: small;
    }
}

.ticket-card {
    .sub {
        color: $gray-light;
        font-size: small;
    }
}

.articles {
    .card {
        border-color: $article-card-border;
        &.is-customer {
            background-color: $article-card-bg;
        }

        .card-body {
            p {
                margin: 0;
            }
        }
    }
}

.bg-task-state {
    color: black;

    &.open {
        background-color: $ticket-status-new;
    }
    &.new {
        background-color: $ticket-status-new;
    }
    &.closed {
        background-color: $ticket-status-closed;
    }
    &.pending-reminder {
        background-color: $ticket-status-pending-reminder;
    }
    &.pending-close {
        background-color: $ticket-status-pending-close;
    }
}
.text-task-state {
    color: black;

    &.open {
        color: $ticket-status-new;
    }
    &.new {
        color: $ticket-status-new;
    }
    &.closed {
        color: $ticket-status-closed;
    }
    &.pending-reminder {
        color: $ticket-status-pending-reminder;
    }
    &.pending-close {
        color: $ticket-status-pending-close;
    }
}

.attachments {
    .attachment-icon {
        color: $ticket-attachment-files-icon;
    }
}
