//.BorderRadius($tl: $border-radius-default, $tr: $border-radius-default, $bl: $border-radius-default, $br: $border-radius-default) {
//  -webkit-border-radius: $tl $tr $br $bl;
//  -moz-border-radius: $tl $tr $br $bl;
//  border-radius: $tl $tr $br $bl;
//}

@mixin sidebar-width($borders, $width) {
  $sidebar-width: $width;

  @each $border in $borders {
    $direction: nth($border, 1);
    @if $direction == 'all' {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: ( $sidebar-width - ( 2 * $size ));
    } @else if $direction == 'right' {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: $sidebar-width - $size;
    } @else if $direction == 'left' {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: $sidebar-width - $size;
    }
    width: $sidebar-width;
  }
}

@mixin borders($borders) {
  @each $border in $borders {
    $direction: nth($border, 1);
    @if $direction == 'all' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border: $size $style $color;
    } @else if $direction == 'top' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-top: $size $style $color;
    } @else if $direction == 'right' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-right: $size $style $color;
    } @else if $direction == 'bottom' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-bottom: $size $style $color;
    } @else if $direction == 'left' {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-left: $size $style $color;
    }
  }
}

@mixin card-accent-variant($color) {
  border-top-width: 2px;
  border-top-color: $color;
}

@mixin glass-card-bg($url) {

  @if $url != '' {
    background: url($url) no-repeat center center;
  } @else {
    background-color: $background-color;
  }
}
@mixin glass-card-bg-before($url) {

  @if $url != '' {
    background: url($url) no-repeat;
  } @else {
    background-color: white;
  }
}

