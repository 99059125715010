body .p-fileupload .p-fileupload-content {
    padding: 0 !important;
    color: $font-color !important;
    display: flex;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    border: 2px dotted $red !important;
}

body .p-fileupload-content {
  .p-messages, .p-progressbar {
    display: none;
  }
  .p-button {
      margin: 0 !important;
      color: $red !important;
      border: 0 !important;
      background-color: transparent !important;
  }
}
