.form-container {
  padding: 20px;
  width: 100%;

  dynamic-ng-bootstrap-form {
    width: 80%;
  }

  .form-container-footer {
    width: 91%;
  }

  .form-group-container {
    margin-bottom: 18px;

    &.pbx-name-input {
      div {
        width: 80%;
      }
    }

    .form-control {
      display: block;
      width: 100%;
      font-size: 0.9rem;
      line-height: 2.5;
      color: #495057;
      background-clip: padding-box;
      border-radius: 0;
      background: $input-background-color;
      outline: 0;
      border: 1px solid $input-border-color;
      padding: 0 5px;
      color: rgba(34, 34, 34, 0.8);

      &.white {
        background: white !important;
      }


      &.is-invalid {
        border: 1px solid red;
      }

      &:disabled, &[readonly] {
        background-color: rgba($input-background-color, .25);
        border: 1px solid rgba($input-background-color, 1);
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(58, 58, 58, 0.6);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(58, 58, 58, 0.6);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(58, 58, 58, 0.6);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(58, 58, 58, 0.6);
    }

    label {
      color: $red;
      font-size: small;
      margin-bottom: 0;
    }

    label.ui-dropdown-label {
      display: block;
      width: 100%;
      font-size: 0.9rem;
      line-height: 2.5;
      color: #495057;
      background-clip: padding-box;
      border-radius: 0;
      background: $input-background-color;
      outline: 0;
      padding: 0 5px;
      color: rgba(34, 34, 34, 0.8);
    }

    .ui-state-default {
      border: 1px solid $input-background-color;
    }

    .ui-state-highlight {
      border-color: $red;
      background: $red;
      color: #FFFFFF;
    }

    .ui-dropdown-trigger.ui-state-default.ui-corner-right {
      background: $input-background-color;
    }

    .invalid-feedback {
      position: absolute;
      width: 80%;
      margin-top: 0;
    }

    .has-error {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        color: red;
      }
    }
  }
}
