/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('/assets/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('/assets/fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* yanone-kaffeesatz-regular - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/yanone-kaffeesatz-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Yanone Kaffeesatz Regular'), local('YanoneKaffeesatz-Regular'),
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-regular.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}
/* yanone-kaffeesatz-700 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/yanone-kaffeesatz-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Yanone Kaffeesatz Bold'), local('YanoneKaffeesatz-Bold'),
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/yanone-kaffeesatz-v9-latin-700.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif, serif;
  color: $font-color;
}

body {
  a, a:hover {
    color: $red;
    text-decoration: none;
  }
}

.login label.red {
  color: $red;
  font-weight: bold;
}

.no-border {
  border: none !important;
}

.license-code {
  height: 8em;
  white-space: pre-wrap;
  word-break: break-word;
}

.app,
app-main {
  background: $background-color;
}

.row {
  margin: 0;
}

#router {
  padding: 0; // please use no padding values on a root object. use p-2 or something like p-sm-2 for responsive design
  //height: calc(100vh - 85px);
}

.circle-red {
  width: 100px;
  height: 100px;
  background-color: $red;
  border-radius: 50%;
  cursor: pointer;
}

.red {
  color: $red !important;
}

.gray {
  color: $gray !important;
}

.disabledLink {
  color: $gray;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.white {
  color: white;
}

.icon-size-big {
  font-size: $icon-size-big;
}
.icon-size-bigger {
  font-size: $icon-size-bigger;
}
.icon-size-bigger-plus {
  font-size: $icon-size-bigger + .2;
}
.icon-size-biggest {
  font-size: $icon-size-biggest;
}
.icon-size-small {
  font-size: $icon-size-small;
}
.icon-size-smaller  {
  font-size: $icon-size-smaller;
}
/*
.footer {
  margin-top: auto;
  z-index: 10;
  text-align: end;
}

.footer-text {
  background: #f5f5f5;
  padding: 2px 10px;
  border-radius: 10px 0 0 0;
}
*/


.text-align-center {
  text-align: center;
}
.text-align-block {
  text-align: justify;
}

.card-h1, .card-h2, .card-h3, .card-h4, .card-h5, .card-h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  margin-right: 10px;
  //color: inherit;

}

.card- {
  &h1 {
    font-size: 1.125rem;
  }
  &h2 {
    font-size: 1.1rem;
  }
  &h3 {
    font-size: 1.05rem;
  }
  &h4 {
    font-size: 1.0rem;
  }
  &h5 {
    font-size: 0.95rem;
  }
  &h6 {
    font-size: 0.9rem;
  }

}

.hover-effect {
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    filter:drop-shadow(0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5));
  }
}

.dlbutton:hover , .dropmenu:hover {
  filter: brightness(200%);
}

.huline {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.badge-icon-container {
  .badge {
    position: absolute;
    right: 16px;
    top: -5px;
  }
}

.h3 {
  font-family: 'Yanone Kaffeesatz', 'Open Sans', sans-serif, serif;
}

.small-exponent {
  font-size: .45em;
  padding-bottom: 20px;
  vertical-align: super;
}

.partner-level-headline {
  font-size: 2.5rem;
  font-family: 'Yanone Kaffeesatz', 'Open Sans', sans-serif, serif;
  color: $red;
}

.cursor- {
  &alias {cursor: alias !important ;}
  &all-scroll {cursor: all-scroll !important ;}
  &auto {cursor: auto !important ;}
  &cell {cursor: cell !important ;}
  &context-menu {cursor: context-menu !important ;}
  &col-resize {cursor: col-resize !important ;}
  &copy {cursor: copy !important ;}
  &crosshair {cursor: crosshair !important ;}
  &default {cursor: default  !important ;}
  &e-resize {cursor: e-resize !important ;}
  &ew-resize {cursor: ew-resize !important ;}
  &grab {cursor: -webkit-grab; cursor: grab !important ;}
  &grabbing {cursor: -webkit-grabbing; cursor: grabbing !important ;}
  &help {cursor: help !important ;}
  &move {cursor: move !important ;}
  &n-resize {cursor: n-resize !important ;}
  &ne-resize {cursor: ne-resize !important ;}
  &nesw-resize {cursor: nesw-resize !important ;}
  &ns-resize {cursor: ns-resize !important ;}
  &nw-resize {cursor: nw-resize !important ;}
  &nwse-resize {cursor: nwse-resize !important ;}
  &no-drop {cursor: no-drop !important ;}
  &none {cursor: none !important ;}
  &not-allowed {cursor: not-allowed !important ;}
  &pointer {cursor: pointer !important ;}
  &progress {cursor: progress !important ;}
  &row-resize {cursor: row-resize !important ;}
  &s-resize {cursor: s-resize !important ;}
  &se-resize {cursor: se-resize !important ;}
  &sw-resize {cursor: sw-resize !important ;}
  &text {cursor: text !important ;}
  &w-resize {cursor: w-resize !important ;}
  &wait {cursor: wait !important ;}
  &zoom-in {cursor: zoom-in !important ;}
  &zoom-out {cursor: zoom-out !important ;}
}

div, span {
  &.wrap-text-translation {
    white-space: pre-line;
  }
}

.clickable-icon, .cursor-pointer {
  cursor: pointer !important;
}
