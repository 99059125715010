/**
 * Styling for PrimeNG component growl
 */

body .ui-growl .ui-growl-item-container.ui-growl-message-error {
    background-color: $red;
    border: 0 none;
    color: white;
}

body .ui-growl .ui-growl-item-container.ui-growl-message-error .ui-growl-image {
  color: white;
}

// Main container element.
.ui-growl {
  // Container of a message item.
  .ui-growl-container {

  }
  // Message element.
  .ui-growl-item {

  }
  // Close icon of a message.
  .ui-growl-icon-close {

    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;

    &:before {
      content: "\f00d";  /* this is your text. You can also use UTF-8 character codes as I do here */
    }
  }
  // Severity icon.
  .ui-growl-image {

  }
  // Container of message texts.
  .ui-growl-message {

  }
  // Summary of the message.
  .ui-growl-title {
  }
}